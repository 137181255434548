<template lang="pug" functional>
  div
    .badge-item-skeleton(
      v-for="i in props.count" :key="i")
      .badge-item-skeleton__checkbox
      .badge-item-skeleton__badge-icon
      .badge-item-skeleton__title

</template>

<script>
export default {
  name: 'BadgeItemSkeleton',

  props: {
    count: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_mixins.scss';

  .badge-item-skeleton {
    padding: 1rem;
    display: flex;
    align-items: center;
    height: 69px;

    &__checkbox {
      height: 18px;
      width: 18px;
      margin-right: 10px;
      border-radius: 4px;
      @include skeleton;
    }

    &__badge-icon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      margin-right: 15px;
      @include skeleton;
    }

    &__title {
      height: 18px;
      width: 120px;
      border-radius: 5px;
      @include skeleton;
    }
  }
</style>
