<template lang="pug">
  v-list
    v-badge(
      v-if="items.length > 1"
      :value="items.length")
      skills-drag-item(
        :item="items[0]")
    skills-drag-item(
      v-else
      :item="items[0]")

</template>

<script>
import SkillsDragItem from './SkillsDragItem'

export default {
  name: 'SkillsDragList',

  props: {
    items: Array
  },

  components: {
    SkillsDragItem
  }
}
</script>

<style lang="scss">
</style>
