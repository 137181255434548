<template lang="pug">
  v-card(elevation)
    .search-result__field
      v-input-search(
        :placeholder="'ui.inputs.type_to_search' | translate"
        type="text"
        v-model="search"
      )
    v-scrollable(
      updateble
      :min-height="20"
      :bottom-offset="15"
      @scroll-complete="onNextPage")
      template(v-if="loaded")
        template(v-if="items.length")
          v-list-item(
            v-for="item in items"
            :key="item.id")
            slot(
              name="icon"
              :item="item")
            v-list-item-content
              v-list-item-title
                slot(
                  name="title"
                  :item="item")
              v-list-item-subtitle
                slot(
                  name="subtitle"
                  :item="item")
            v-list-item-actions
              v-btn(
                v-if="!added.find(el => el.id === item.id)"
                icon
                success
                @click="onAdd(item)")
                i.fa.fa-plus
              v-btn(
                v-else
                icon
                danger
                @click="onRemove(item)")
                i.fa.fa-minus

        .search-result__empty(v-else)
          v-list-empty(v-if="search.length")
            i.icon.icon--sm.icon--no-result
            span {{ 'ui.labels.no_results' | translate }}
          v-list-empty(v-else)
            i.icon.icon--sm.icon--empty
            span {{ 'ui.labels.list_is_empty' | translate }}

      slot(name="list-end")

</template>

<script>
export default {
  name: 'SearchResult',

  props: {
    value: String,
    added: Array,
    items: Array,
    loaded: Boolean
  },

  data: () => ({
    search: ''
  }),

  mounted () {
    this.search = this.value || ''
  },

  methods: {
    onAdd (item) {
      this.$emit('add', item)
    },

    onRemove (item) {
      this.$emit('remove', item)
    },

    onNextPage () {
      this.$emit('next')
    }
  },

  watch: {
    search (value) {
      this.$emit('search', value)
    }
  }
}
</script>

<style lang="scss">
  .search-result {
    &__field {
      padding: 1rem;
    }

    &__empty {
      padding-bottom: 2rem;
    }
  }
</style>
