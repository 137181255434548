<template lang="pug">
  v-list-item
    span.mr-3
      badge(
        width="25"
        :color="item.color"
        :level="item.starsCount")
    v-list-item-content
      v-list-item-title {{ item.name }}

</template>

<script>
import Badge from '@/components/svg/Badge'

export default {
  name: 'SkillsDragItem',

  props: {
    item: Object
  },

  components: {
    Badge
  }
}
</script>

<style lang="scss">
</style>
