<template lang="pug">
  .skill-detail-modal(
    v-if="showModal")
    gradation-tabs(
      hide-actions
      :active-tab-index="activeTabIndex"
      :gradational-badges="gradationalBadges"
      @setActiveTabIndex="setActiveTabIndex")
    v-card(elevation)
      v-card-title
        h2.title {{ node.displayName }}
      v-card-content
       v-btn(
         block
         :danger="isSelected"
         @click="onClick") {{ (isSelected ? 'ui.buttons.unselect' : 'ui.buttons.select') | translate }}
      template(v-if="node && node.description")
        v-divider.mb-3(in-card)
        v-scrollable(
          :max-height="280")
          v-card-actions(stack)
            .title-2.mb-2 Description
            div(v-html="descriptionHtml")

</template>

<script>
import marked from 'marked'

import { NODE_TYPE } from '@/util/constants.js'
import { mapGetters } from 'vuex'

import helpers from '@/util/helpers'

import GradationTabs from '@/components/ui/GradationTabs'

export default {
  name: 'SkillDetailModal',

  components: {
    GradationTabs
  },

  props: {
    added: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    activeTabIndex: 0
  }),

  methods: {
    onClick () {
      if (this.isSelected) {
        this.$emit('remove', this.node)
      } else {
        this.$emit('add', this.node)
      }
    },

    setActiveTabIndex (index) {
      this.activeTabIndex = index
    }
  },

  computed: {
    ...mapGetters('nodeTmp', [
      'cursorNode'
    ]),

    isSelected () {
      return this.added.some(item => this.node &&
        this.node.id === item.id)
    },

    showModal () {
      return this.cursorNode &&
        this.cursorNode.props.typeId !== NODE_TYPE.CATEGORY
    },

    descriptionHtml () {
      const description = helpers.escapeHTML((this.node && this.node.description) || '')
      return marked(description)
    },

    gradationalBadges () {
      return this.cursorNode
        ? [this.cursorNode.props, ...this.cursorNode.props.gradationalSubBadges]
        : []
    },

    node () {
      return this.cursorNode.props.typeId === NODE_TYPE.BADGE
        ? this.cursorNode.props
        : this.gradationalBadges[this.activeTabIndex]
    }
  },

  watch: {
    cursorNode () {
      this.activeTabIndex = 0
    }
  }
}
</script>

<style lang="scss">
  .skill-detail-modal {
    width: 280px;
  }
</style>
