<template lang="pug">
  .skills-list
    right-panel-empty-state(
      v-if="!(selectedDepartment && selectedDepartment.isLeaf)")

    template(v-else)
      .skills-list__header
        h3.title {{ selectedDepartment.data.name }}

      .skills-list__detail
        span
          template(v-if="addedItemsLoaded")
            | {{ badgesCount }} {{ 'ui.labels._badges' | translate }}
        .skills-list__detail-actions
          v-btn(
            icon
            gray
            @click="showSelectModal = true")
            i.fas.fa-external-link-alt
          skills-search(
            :added="addedBadges"
            @add="onItemAdd"
            @remove="onItemRemove"
            @toggle="onToggleSearch")

      v-scrollable(
        :bottom-offset="25"
        @scroll-complete="onNextPage"
        @on-scroll="onScrollList")
        template(v-if="addedItemsLoaded")
          template(v-for="item in addedBadges")
            drag(
              :transfer-data="{ item, selected }"
              @dragstart="onDragStart"
              @dragend="onDragEnd")
              skills-list-item(
                :item="item"
                :is-selected="selected.indexOf(item) !== -1"
                @select="onSelect"
                @remove="onItemRemove"
                @close-dropdown-cb="setCloseDropdown")
              template(slot="image")
                skills-drag-list(
                  :items="getDragedItems(item)")

          badge-item-skeleton(
              :count="skeletons")

        template(v-else)
          badge-item-skeleton(:count="5")

      v-dialog(
        width="970"
        v-model="showSelectModal")
        skills-select-modal(
          :addedBadges="addedBadges"
          @add="onItemAdd"
          @remove="onItemRemove"
          @close="showSelectModal = false")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

// import Badge from '@/components/svg/Badge'
import BadgeItemSkeleton from '@/components/common/lists/BadgeItemSkeleton'

import RightPanelEmptyState from '../../../common/RightPanelEmptyState'

import SkillsSelectModal from '../modals/SkillsSelectModal'
import SkillsDragList from './SkillsDragList'
import SkillsListItem from './SkillsListItem'
import SkillsSearch from './SkillsSearch'

import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'

export default {
  name: 'SkillsList',

  mixins: [scrollPaginationSkeletonsMixin],

  components: {
    SkillsSearch,
    BadgeItemSkeleton,
    RightPanelEmptyState,
    SkillsSelectModal,
    SkillsDragList,
    SkillsListItem
  },

  mounted () {
    this.loadAddedBadges()
  },

  data: () => ({
    selected: [],
    addedItemsLoaded: false,
    showSelectModal: false,
    closeDropdownCb: null
  }),

  methods: {
    ...mapActions('organization', [
      'addBadge',
      'removeBadge',
      'loadLinkedSkills',
      'setClipboardItems',
      'activateExternalDragMode',
      'deactivateExternalDragMode'
    ]),

    async loadAddedBadges () {
      if (this.selectedDepartment) {
        this.initScrollPagination(
          this.selectedDepartment.data.badges,
          this.loadLinkedSkills
        )
        this.addedItemsLoaded = false
        await this.loadLinkedSkills()
        this.addedItemsLoaded = true
      } else {
        this.selected = []
      }
    },

    onSelect (data) {
      if (data.selected) {
        this.selected.push(data.item)
      } else {
        const index = this.selected
          .indexOf(data.item)
        if (index !== -1) {
          this.selected.splice(index, 1)
        }
      }
    },

    onItemAdd (item) {
      this.addBadge(item)
    },

    onItemRemove (item) {
      this.removeBadge(item)
    },

    getDragedItems (item) {
      if (this.selected
        .includes(item)) {
        return this.selected
      }
      return [
        ...this.selected,
        item
      ]
    },

    onDragStart (data) {
      const items = this.getDragedItems(data.item)
      this.setClipboardItems(items)
      this.activateExternalDragMode()
    },

    onDragEnd () {
      this.deactivateExternalDragMode()
    },

    setCloseDropdown (cb) {
      this.closeDropdownCb = cb
    },

    onScrollList () {
      if (this.closeDropdownCb) {
        this.closeDropdownCb()
        this.closeDropdownCb = null
      }
    },

    onToggleSearch (value) {
      if (value) {
        this.loadLinkedSkills(true)
      }
    }
  },

  computed: {
    ...mapGetters('badges', [
      'badges'
    ]),

    ...mapGetters('organization', [
      'selectedDepartment'
    ]),

    addedBadges () {
      return this.selectedDepartment
        ? this.selectedDepartment.data.badges.items
        : null
    },

    badgesCount () {
      return this.selectedDepartment
        ? this.selectedDepartment.data.badges.pagination.total
        : this.addedBadges.length
    }
  },

  watch: {
    selectedDepartment (value) {
      if (value) {
        this.loadAddedBadges()
      }
    },

    addedBadges (value) {
      if (value) {
        this.selected = []
      }
    },

    showSelectModal (value) {
      if (value) {
        this.loadLinkedSkills(true)
      }
    }
  }
}
</script>

<style lang="scss">
  .skills-list {
    height: 100%;

    &__header {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1rem 0;
    }

    &__detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 1rem;
      padding-top: .5rem;

      &-actions {
        display: flex;
      }
    }
  }
</style>
