<template lang="pug">
  v-list-item
    v-checkbox(
      :value="isSelected"
      @input="onSelect")
    span.mr-3
      badge(
        width="25"
        :color="item.color"
        :level="item.starsCount")
    v-list-item-content
      v-list-item-title {{ item.displayName }}
    v-list-item-actions
      v-dropdown(
        ref="dropdown"
        right
        scrollable-fixed
        auto-close
        width-auto
        @toggle="toggleDropdown")
        v-btn(
          icon
          gray)
          i.fa.fa-ellipsis-v
        template(slot="content")
          div(style="width:180px")
            v-card(elevation)
              v-list-item(@click="$emit('remove', item)")
                v-list-item-content
                  v-list-item-title
                    span.danger {{ 'ui.buttons.remove' | translate }}

</template>

<script>
import Badge from '@/components/svg/Badge'

export default {
  name: 'SkillsListItem',

  components: {
    Badge
  },

  props: {
    item: Object,
    isSelected: Boolean
  },

  methods: {
    toggleDropdown (value) {
        if (value) {
          this.$emit('close-dropdown-cb', this.$refs.dropdown.closeDropdown)
        }
      },
    onSelect () {
      this.$emit('select', { selected: !this.isSelected, item: this.item })
    }
  }
}
</script>

<style lang="scss">
</style>
