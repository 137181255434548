<template lang="pug">
  v-dropdown(
    right
    width-full
    @toggle="onToggle")
    v-btn.mr-3(
      icon
      gray)
      i.fa.fa-plus
    template(slot="content")
      search-result(
        :value="paginationParams.q"
        :loaded="loaded"
        :items="badges"
        :added="added"
        @search="onSearch"
        @add="onItemAdd"
        @remove="onItemRemove"
        @next="onNextPage")
        template(
          slot="icon"
          slot-scope="{ item }")
          span.mr-3
            badge(
              width="25"
              :color="item.color"
              :level="item.starsCount")
        template(
          slot="title"
          slot-scope="{ item }")
          span {{ item.displayName }}
        template(
          slot="list-end")
          badge-item-skeleton(
            small
            :count="skeletons")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import SearchResult from '../common/SearchResult'
import Badge from '@/components/svg/Badge'
import BadgeItemSkeleton from '@/components/common/lists/BadgeItemSkeleton'
import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'

export default {
  name: 'SkillsSearch',

  mixins: [scrollPaginationSkeletonsMixin],

  components: {
    SearchResult,
    BadgeItemSkeleton,
    Badge
  },

  props: {
    added: Array,
    position: Object
  },

  data: () => ({
    loaded: false
  }),

  computed: {
    ...mapGetters('badges', [
      'badges',
      'paginationParams'
    ])

  },

  async mounted () {
    await this.loadBadges({ isPushed: true })
    this.initScrollPagination(
      { pagination: this.paginationParams },
      this.loadNextPage
    )
    this.loaded = true
  },

  methods: {
    ...mapActions('badges', [
      'loadBadges',
      'searchBadges'
    ]),

    async loadNextPage () {
      const page = this.paginationParams.page
        ? this.paginationParams.page + 1
        : 1
      const params = {
        page
      }
      if (this.paginationParams.q) {
        params.q = this.paginationParams.q
        await this.searchBadges({ params, isPushed: true })
      } else {
        await this.loadBadges({ params, isPushed: true })
      }
    },

    onSearch (search) {
      const params = {
        q: search,
        page: 1
      }
      this.searchBadges({ params })
    },

    onItemAdd (item) {
      this.$emit('add', item)
    },

    onItemRemove (item) {
      this.$emit('remove', item)
    },

    onToggle (param) {
      this.$emit('toggle', param)
    }
  }
}
</script>

<style lang="scss">
</style>
