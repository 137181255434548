<template lang="pug">
  v-card
    v-card-title
      h2.title {{ 'ui.labels.select_badges' | translate }}
    .tree-map
      tree(
        v-if="loaded"
        :tree="globalTree"
        :filtered-badges="addedBadges")
      .tree-map__preloader(v-else)
        v-preloader
      .tree-map__right
        skill-detail-modal(
          :added="addedBadges"
          @add="onAdd"
          @remove="onRemove")
    v-card-actions
      v-btn(
        @click="$emit('close')")
        | {{ 'ui.labels.done' | translate }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Tree from '@/components/tree/Tree'
import SkillDetailModal from './SkillDetailModal'

export default {
  name: 'SkillsSelectModal',

  components: {
    Tree,
    SkillDetailModal
  },

  props: {
    addedBadges: {
      type: Array,
      default: () => []
    }
  },

  async mounted () {
    if (!this.globalTree && this.isLoadLazyTree) {
      await this.loadTreeById({ id: null, lazy: true })
      if (this.isLoadLazyTree) {
         this.loadTreeById({ id: null, lazy: false })
      }
    }
    this.rearrangeTree()
    this.loaded = true
  },

  data: () => ({
    loaded: false
  }),

  methods: {
    ...mapActions('trees', [
      'loadTreeById',
      'rearrangeTree'
    ]),

    onAdd (item) {
      this.$emit('add', item)
    },

    onRemove (item) {
      this.$emit('remove', item)
    }
  },

  computed: {
    ...mapGetters('trees', [
      'globalTree',
      'isLoadLazyTree'
    ])
  }
}
</script>

<style lang="scss">
  .tree-map {
    position: relative;
    padding: 24px 0;
    height: 60vh;

    &__preloader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &__right {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
</style>
